import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NotificationSystem from 'react-notification-system'
import { AlertActionCreators } from 'app/reducers/AlertRedux'

class AlertSystem extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      hide: PropTypes.func.isRequired
    }).isRequired,
    alerts: PropTypes.array.isRequired
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const system = this.ns
    const { alerts } = nextProps
    const uids = alerts.map(n => n.uid)
    ;(system.state.alerts || []).forEach(n => {
      if (uids.indexOf(n.uid) < 0) {
        system.removeNotification(n.uid)
      }
    })

    alerts.forEach(n => {
      system.addNotification({
        ...n,
        onRemove: () => {
          this.props.actions.hide(n.uid)
          n.onRemove && n.onRemove()
        }
      })
    })
  }

  shouldComponentUpdate(nextProps) {
    return this.props !== nextProps
  }

  render() {
    return <NotificationSystem ref={el => (this.ns = el)} />
  }
}

const mapStateToProps = state => ({ alerts: state.alerts })

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      hide: AlertActionCreators.hide
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertSystem)
