import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Router, { withRouter } from 'next/router'
import Head from 'next/head'
import NProgress from 'nprogress'
import exenv from 'exenv'

import { ContactJourneyCreators } from 'app/reducers/ContactJourneyRedux.js'
import { AuthActionCreators } from 'app/reducers/AuthRedux'
import { AppActionCreators } from 'app/reducers/AppRedux'
import { getSessionData, getTokenFields } from 'app/selectors'
import availablePages from 'app/commonRoutes'
import config from 'config'

import NavigationBar from 'components/Header/NavigationBar'
import Footer from 'components/Footer'
import { FullPageSpinner } from 'controls/Spinner'
import AlertSystem from 'components/AlertSystem'
import FeatureFlag from 'components/FeatureFlag'

import ChatBot from './ChatBot'
import UserSessionCheck from './UserSessionCheck'
import { userRequestLogout } from './utils'
import { global, forms, text, nProgressStyle, layout } from './styles'

const GOOGLE_MAPS_API_KEY = config('GOOGLE_MAPS_API_KEY')

Router.onRouteChangeStart = url => {
  NProgress.start()
}

Router.onRouteChangeComplete = url => {
  NProgress.done()
}

Router.onRouteChangeError = () => NProgress.done()

class Layout extends Component {
  static propTypes = {
    // from state
    sessionData: PropTypes.object.isRequired,
    decodedToken: PropTypes.object,

    // from props
    urlData: PropTypes.object.isRequired,
    navBarProps: PropTypes.object,
    typeOfLogin: PropTypes.oneOf(['broker', 'contact']),

    //misc
    className: PropTypes.string
  }

  static defaultProps = {
    sessionData: {},
    urlData: {},
    navBarProps: {},
    typeOfLogin: 'broker',
    className: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      tokenWasUpdated: false,
      hasError: false,
    }

    this.currentPath = props.urlData?.pathname

    let redirectData = props.urlData?.query?.redirect

    if (redirectData) {
      try {
        redirectData = atob(redirectData)
        redirectData = JSON.parse(redirectData)
        this.redirectData = redirectData
      } catch (e) {
        this.redirectData = null
      }
    }

    const currentPageObject = availablePages.find(
      page => page.page === this.currentPath.substring(1),
    )
    this.isPrivate = currentPageObject?.privacy === 'PRIVATE'
    this.validatePrivacyRedirection(props)
  }

  // static getDerivedStateFromError(error) {
  //   console.log('getDerivedStateFromError', error)
  //   return { hasError: true }
  // }

  componentDidCatch(error, errorInfo) {
    console.error('componentDidCatch', error, errorInfo)

    this.setState({ hasError: true })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.validatePrivacyRedirection(nextProps)

    if (!nextProps.hasAppConfig) {
      this.props.actions.getConfigRequest()
    }
  }

  validatePrivacyRedirection(nextProps) {
    const { sessionData, typeOfLogin } = nextProps
    const tokenRefreshed = nextProps.sessionData.tokenRefreshed

    // wait for state reconciliation to complete (i.e.: when the app first loads)
    if (!tokenRefreshed) {
      return
    }

    // page is private and the user is unauthorized
    if (this.isPrivate && !sessionData.isAuthenticated && tokenRefreshed) {
      const { pathname, query } = this.props.urlData
      const redirectData = {
        pathname,
        query,
        asPath: this.props.router.asPath
      }

      if (typeOfLogin === 'contact') {
        if (!this.state.tokenWasUpdated) {
          this.setState({ tokenWasUpdated: true }, () =>
            this.props.actions.updateOriginalContactToken(query.token)
          )
          return
        }

        this.props.actions.sendContactJourneyEvent('contact-journey', 'redirect')

        window.location.assign(`/login-contact?redirect=${btoa(JSON.stringify(redirectData))}`);

      } else if (userRequestLogout(this.props.sessionData, nextProps.sessionData)) {
          window.location.assign('/ingresar');
        } else {
            window.location.assign(`/ingresar?redirect=${btoa(JSON.stringify(redirectData))}`);
      }

      return
    }

    // if it's login page, check if the user is logged in for redirection
    if (this.routeIsLoginPage(this.currentPath) || this.routeIsLoginPage(this.props.router.asPath)) {
      // if the login process has just completed successfully
      if (sessionData.isAuthenticated) {
        if(typeOfLogin === 'contact') {
          if(!Boolean(this.redirectData) ) {
            // ToDo: default route
            window.location.assign('/')
            return
          }
          const { redirectData: r } = this
          // We need to push method because this update the path of the current page
          // and rerunning its getInitialProps.
          window.location.assign(r.asPath)
          return
        }

        if (!Boolean(this.redirectData)) {
          window.location.assign('/dashboard')
          return
        }

        const { redirectData: r } = this

        window.location.assign(r.asPath)
      }
    }
  }

  routeIsLoginPage = path =>
    path && path
      .replace('/', '')
      .toLowerCase()
      .indexOf('login') === 0


  render() {
    const { sessionData, hideSessionModal, className, typeOfLogin } = this.props

    if (this.state.hasError) {
      throw new Error('_app')
    }

    const stateIsReconciling = this.isPrivate && (!sessionData.rehydrated || !sessionData.isAuthenticated)

    const children =
      stateIsReconciling ? (
        <FullPageSpinner isOpen />
      ) : (
        this.props.children
      )

    const { canUseDOM } = exenv

    return (
      <div className={className} style={{ width: '100%' }}>
        <AlertSystem />
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          {(!canUseDOM || (canUseDOM && !window.google)) && (
            <script
              cookie-consent="targeting"
              type="text/javascript"
              src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
              async
              defer
            />
          )}
        </Head>
        <FeatureFlag
          name="layout-head-menu"
          defaultChildren={
            this.props.showNavBar && (
              <NavigationBar {...this.props.navBarProps} />
            )
          }
        />
        {children}
        {this.props.showFooter && <Footer />}
        <ChatBot isPrivate={this.isPrivate} typeOfLogin={typeOfLogin} />
        {!stateIsReconciling && (
          <UserSessionCheck
            isPrivate={this.isPrivate}
            typeOfLogin={typeOfLogin}
            hideSessionModal={hideSessionModal}
          />
        )}
        <style jsx global>
          {global}
        </style>
        <style jsx global>
          {forms}
        </style>
        <style jsx global>
          {text}
        </style>
        <style jsx global>
          {nProgressStyle}
        </style>
        <style jsx>
          {layout}
        </style>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const decodedToken = getTokenFields(state)
  const sessionData = getSessionData(state)
  const hasAppConfig = Object.keys(state.app.config).length > 0

  return {
    ...ownProps,
    hasAppConfig,
    decodedToken,
    sessionData
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateOriginalContactToken: AuthActionCreators.updateOriginalContactToken,
      sendContactJourneyEvent: ContactJourneyCreators.sendContactJourneyEvent,
      getConfigRequest: AppActionCreators.getConfigRequest,
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Layout))
