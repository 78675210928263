import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { getSessionData } from 'app/selectors'

function UserSessionCheck({ isPrivate, typeOfLogin, hideSessionModal }) {
  const router = useRouter()
  const { tokenRefreshed } = useSelector(getSessionData)
  const { shouldBeLogout } = useSelector(state => state.userSession)

  useEffect(() => {
    // wait for state reconciliation to complete (i.e.: when the app first loads)
    // Don't logout if user is a contact.
    if (!tokenRefreshed || typeOfLogin === 'contact' || hideSessionModal) {
      return
    }

    // Same validation as Layout.validatePrivacyRedirection method
    if (isPrivate && shouldBeLogout && tokenRefreshed) {
      window.location.assign('/salir')
    }
  }, [tokenRefreshed, typeOfLogin, isPrivate, shouldBeLogout, hideSessionModal])

  return null
}

export default UserSessionCheck
