import React, { useEffect } from 'react'
import Head from 'next/head'
import { useSelector } from 'react-redux'

import { getSessionData } from 'app/selectors'

const ChatBot = ({ isPrivate, typeOfLogin }) => {
  const { isAuthenticated } = useSelector(getSessionData)

  return isPrivate && isAuthenticated && typeOfLogin === 'broker' ? (
    <Head>
      <script type="text/javascript">
        {`
            window.chatwootSettings = {
                type: "standard",
                launcherTitle: "Chatea con nosotros",
            };
            (function (d, t) {
            var BASE_URL = "https://app.chatwoot.com";
            var g = d.createElement(t),
                s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                window.chatwootSDK.run({
                websiteToken: "reFQXdBs6kxy7SkS5paJ3VsT",
                baseUrl: BASE_URL,
                });
            };
            })(document, "script");
        `}
      </script>
    </Head>
  ) : null
}

export default ChatBot
